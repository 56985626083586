import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Bouton from "../Components/Bouton/Bouton";

function JoinGame(props) {
    const [newPlayer, setNewPlayer] = useState();
    const [roomCode, setRoomCode] = useState();
    const history = useHistory();
    
    useEffect(() => {
        document.body.className = 'joingame'
        document.title = `ZGQ : Rejoindre une partie`;
    }, [])

    const sendsocket = (obj) => {   
        props.socket.emit('joinRoom', obj);
    }

    const validateNewPlayer = (event) => {
        let error = false
        event.preventDefault();
                
        let nameAlert = document.getElementById('playerNameAlert')
        let roomAlert = document.getElementById('roomAlert')

        if(newPlayer) {
            if(newPlayer.length <= 3) {
                nameAlert.innerHTML = '<span class="px-2 text-danger">est trop court ...</span>';
                error = true
            } else {
                nameAlert.innerHTML = '<span class="px-2 text-success">est parfait !</span>';
            }
        } else {
            error = true
        }

        if(roomCode) {
            if(roomCode.length !== 6) {
                roomAlert.innerHTML = '<span class="px-2 text-danger">n\'est pas de la bonne longueur...</span>';
                error = true
            } else {
                roomAlert.innerHTML = '<span class="px-2 text-success">a l\'air d\'être correct !</span>';
            }
        } else {
            error = true
        }

        if(!error) {
            sendsocket({
                name : newPlayer,
                room : roomCode,
                creator: false
            })
            history.push({
                pathname: `/lobby/${roomCode}`
            });
        }
    }

    return (

        <>
            <div className='container'>
                <div className='row no-gutters curvy-container'>
                    <div className='col-8 col-md-6 my-4 mx-auto'>
                        <form>
                            <div className="form-group">
                                <label htmlFor="playerName">Votre nom</label>
                                <p id="playerNameAlert" className="fw-bold mx-1 d-inline-block"></p>
                                <span> : {newPlayer && newPlayer}</span>
                                <input type="text" className="form-control" id="playerName" placeholder="Your name" onChange={(event) => setNewPlayer(event.target.value)} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="GamePassword">Game password</label>
                                <p id="roomAlert" className="fw-bold mx-1 d-inline-block"></p>
                                <span> : {roomCode && roomCode}</span>
                                <input type="text" className="form-control" id="GamePassword" placeholder="Game password" onChange={(event) => setRoomCode(event.target.value)} />
                            </div>
                            <Bouton className="btn btn-primary" clic={(event) => validateNewPlayer(event)}>Rejoindre</Bouton>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
}

export default JoinGame;