import Bouton from "../../Bouton/Bouton";

function Reponse(props) {
    return (
        <div className={`reponse reponse-${props.id} col-12 col-md-6 col-xxl-3 p-2 d-flex align-items-center justify-content-center`} style={{'order':props.order}}>
            <Bouton className="btn btn-primary w-100 h-100 m-0" clic={() => props.clic()}>{props.children}</Bouton>
        </div>
    );
}

export default Reponse;