import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

function Results() {
    const location = useLocation();
    const history = useHistory();
    const [maxScore, setMaxScore] = useState(0)

    useEffect(() => {
        calcMaxScore()
    }, [])

    const calcMaxScore = () => {
        let questions = location.state.questions
        let calcScore = 0
        
        for (let i = 0; i < questions.length; i++) {
            calcScore = calcScore + questions[i].difficulty
        }

        setMaxScore(calcScore)
    }


    return (

        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Résultats de la partie <b>{location.state.code}</b></h2>
                        <h3>Score : {location.state.score} / {maxScore}</h3>
                        <h6>Bonnes réponses</h6>
                        <div>
                            {
                                location.state.questions.map(question => {
                                    return (
                                        <div className="mb-3" key={question.id}>
                                            <p><b>{question.question}</b></p>
                                            <span>{question.possibilities[question.answer - 1]}</span>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>                
            </div>
        </>

    );
}



export default Results;