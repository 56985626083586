const Questions = [
    {
        "id" : 1,
        "difficulty" : 1,
        "category"	: "Triple A",
        "type" : "WatZeFuck" ,
        "image" : "",
        "sound" : "",
        "question" : "Un jeune orphelin adopté par des habitants de la forêt rencontre une princesse et décide de sauver le monde !",
        "possibilities" : ["Mario","Zelda","Animal Crossing","Metroid"],
        "answer" : 2,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 2,
        "difficulty" : 2,
        "category"	: "Indie",
        "type" : "WatZeFuck" ,
        "image" : "",
        "sound" : "",
        "question" : "Un petit fil de laine part à l'aventure et rencontre un autre fil de laine avec qui il se lie pour partir en exploration.",
        "possibilities" : ["Cuphead","Hollow Knight","Unravel","It takes Two"],
        "answer" : 3,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 3,
        "difficulty" : 1,
        "category"	: "Indie",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu est considéré comme étant le premier jeu indépendant ?",
        "possibilities" : ["Rogue","Metroid","Pacman","Unreal Engine"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 4,
        "difficulty" : 4,
        "category"	: "Indie",
        "type" : "CKOI" ,
        "image" : "https://i.ibb.co/QDTgj5k/340937367-145031951860749-5678668492577174765-n.png",
        "sound" : "",
        "question" : "De quel jeu s'agit-il ?",
        "possibilities" : ["It takes two","Unravel","We Were Here","Time Handlers"],
        "answer" : 4,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 5,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu a le cout de développement le plus élevé de l'histoire. (Hors cout Marketing) (en 2023) ?",
        "possibilities" : ["Red Dead Redemption 2","Destiny","Cyberpunk 2077","GTA 5"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 5,
        "difficulty" : 5,
        "category"	: "Indie",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "A combien s'élève le cout de rachat de Minecraft par Microsoft en 2014 ?",
        "possibilities" : ["L'euro symbolique","150 millions de $","1 milliard de $","2,5 milliards de $"],
        "answer" : 4,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 6,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu Assassin's Creed se déroule en France ?",
        "possibilities" : ["Assassin's Creed: Mirage","Assassin's Creed: Révolution","Assassin's Creed: Unity","Assassin's Creed III"],
        "answer" : 3,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 7,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu n'a pas été développé par Ubisoft ?",
        "possibilities" : ["The Settlers : New Allies","Watch Dogs","Assassin's Creed","Tomb Raider"],
        "answer" : 4,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 8,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "WatZeFuck" ,
        "image" : "",
        "sound" : "",
        "question" : "Un jeune homme part de chez lui pour prendre part à des combats d'animaux sauvages",
        "possibilities" : ["Pikmin","Animal Crossing","Call of Duty","Pokémon"],
        "answer" : 4,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 9,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "CKOI" ,
        "image" : "https://i0.wp.com/www.game-lover.org/wp-content/uploads/2017/04/HzD-front.jpg?fit=1920%2C1080&ssl=1",
        "sound" : "",
        "question" : "Quel est ce jeu ?",
        "possibilities" : ["Zelda","Horizon Zero Dawn","Ark: Survival Evolved","Tomb Raider"],
        "answer" : 2,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 10,
        "difficulty" : 1,
        "category"	: "Triple A",
        "type" : "CKOI" ,
        "image" : "https://static.fnac-static.com/multimedia/Images/FD/Comete/136329/CCP_IMG_ORIGINAL/1788613.jpg",
        "sound" : "",
        "question" : "Quel est ce Battle Royale ?",
        "possibilities" : ["Fortnite","H1Z1 : Just Survive","Call of duty : Warzone","PUBG"],
        "answer" : 4,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 11,
        "difficulty" : 3,
        "category"	: "MOBA",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu a inventé le type de jeu MOBA ?",
        "possibilities" : ["Dota","League of Legends","Heroes of the Storm","Starcraft"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 12,
        "difficulty" : 1,
        "category"	: "Indie",
        "type" : "WatZeFuck" ,
        "image" : "",
        "sound" : "",
        "question" : "Des tictacs sautent, rebondissent et tombent dans le vide dans le but d'attraper une couronne.",
        "possibilities" : ["Fall Guys","Among Us","League of Legends","Minecraft"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 13,
        "difficulty" : 4,
        "category"	: "Triple A",
        "type" : "CKOI" ,
        "image" : "",
        "sound" : "",
        "question" : "Des enfants subissent des modifications génétiques pour combattre des bêtes sauvages féroces.",
        "possibilities" : ["The Witcher","Pokémon","Horizon Zero Dawn","Halo"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 14,
        "difficulty" : 5,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Combien de Pokémons existe-t-il dans les 9 premières générations ?",
        "possibilities" : ["509","756","1015","1244"],
        "answer" : 3,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 15,
        "difficulty" : 5,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "En quelle année a été créé League of Legends ?",
        "possibilities" : ["2007","2009","2011","2013"],
        "answer" : 2,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 15,
        "difficulty" : 5,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Que créait Nintendo à sa création ?",
        "possibilities" : ["Des cartes de jeu","Des nouilles instantanées","Des jeux vidéos","Des kimonos"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 16,
        "difficulty" : 5,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel est le nom du premier jeu créé par Nintendo ?",
        "possibilities" : ["Computer Othello","Mario","Donkey Kong","Zelda"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 17,
        "difficulty" : 4,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Quelle est l'entreprise la plus récente ?",
        "possibilities" : ["Square Enix","Ubisoft","Nintendo","Capcom"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 18,
        "difficulty" : 1,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Que n'est-il pas possible de faire dans un jeu Zelda ?",
        "possibilities" : ["Chevaucher un dinosaure","Rouler à moto","Voler sur un oiseau","Naviguer sur un bateau"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 19,
        "difficulty" : 4,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Combien y a-t-il eu de jeux Prince of Persia jusqu'en 2023 ? (hors portages)",
        "possibilities" : ["3","6","8","10"],
        "answer" : 4,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 20,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Combien y a-t-il de générations de Pokémons (En 2023) ?",
        "possibilities" : ["6","8","9","10"],
        "answer" : 3,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 21,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu Pokémon n'existe pas ?",
        "possibilities" : ["Pokémon Racing","Pokémon Quest","Pokémon Ranger","Pokémon Café Remix"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 22,
        "difficulty" : 3,
        "category"	: "Indie",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel acteur n'est pas présent dans le jeu Brofoce ?",
        "possibilities" : ["Jason Statham","Mister T","Will Smith","Chuck Norris"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 23,
        "difficulty" : 3,
        "category"	: "Indie",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Lequel de ces jeux n'est pas un Rogue Like ?",
        "possibilities" : ["Super Meat Boy","Hades","The binding of Isaac","Slay the Spire"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 24,
        "difficulty" : 5,
        "category"	: "Indie",
        "type" : "CKOI" ,
        "image" : "https://www.japanmania.fr/wp-content/uploads/2014/08/inde.jpg",
        "sound" : "",
        "question" : "Quel est ce jeu ?",
        "possibilities" : ["Journey","Dead Cells","Risk of Rain","Never Alone"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 25,
        "difficulty" : 4,
        "category"	: "Indie / Triple A",
        "type" : "Game Awards" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu a remporté les Game Awards 2018 ?",
        "possibilities" : ["God of War","Red Dead Redemption 2","Celeste","Monster Hunter : World"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 26,
        "difficulty" : 4,
        "category"	: "Indie / Triple A",
        "type" : "Game Awards" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu a remporté les Game Awards 2019 ?",
        "possibilities" : ["Sekiro","Death Stranding","Control","Destiny 2"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 27,
        "difficulty" : 4,
        "category"	: "Indie / Triple A",
        "type" : "Game Awards" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu a remporté les Game Awards 2020 ?",
        "possibilities" : ["The Last of Us : Part II","Ghost of Tsushima","Tell me why","Among Us"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 28,
        "difficulty" : 4,
        "category"	: "Indie / Triple A",
        "type" : "Game Awards" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu a remporté les Game Awards 2021 ?",
        "possibilities" : ["It Takes Two","Deathloop","Life is Strange : True Colors","Kena : Bridge of Spirit"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 29,
        "difficulty" : 4,
        "category"	: "Indie / Triple A",
        "type" : "Game Awards" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu a remporté les Game Awards 2022 ?",
        "possibilities" : ["Elden Ring","Stray","Horizon Forbidden West","God of War : Ragnarok "],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 30,
        "difficulty" : 5,
        "category"	: "Indie",
        "type" : "CKOI" ,
        "image" : "https://m1.quebecormedia.com/emp/emp/lanaa5161d42-02d8-4f55-8b4a-fab33bb9eb87_ORIGINAL.jpg?impolicy=crop-resize&x=0&y=0&w=1920&h=1080&width=1200",
        "sound" : "",
        "question" : "Quel est ce jeu ?",
        "possibilities" : ["Planet of Lana","Inside","Guacamelee","Dead Cells"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 31,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Lequel de ces jeux n'existe pas ?",
        "possibilities" : ["Ghostwire : Taiwan","Assassin's Creed : Black Flag","Tomb Raider : Underworld","Far Cry Primal"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 32,
        "difficulty" : 5,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "Comment Hideo Kojima et son équipe ont-ils procédé pour créer Metal Gear Solid en 1998 ?",
        "possibilities" : ["En utilisant des Legos","En faisant une maquette carton","En jouant à d'autres jeux 3D","En démontant des meubles"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 33,
        "difficulty" : 2,
        "category"	: "Casual",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "De quel pays est originaire le jeu Tetris ?",
        "possibilities" : ["Russie","France","Japon","Etats-Unis"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 34,
        "difficulty" : 4,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Laquelle de ces séries TV a eu droit à un jeu vidéo ?",
        "possibilities" : ["Desperate Housewives","Teen Wolf","The Mentalist","Californication"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 35,
        "difficulty" : 5,
        "category"	: "Casual",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "En quelle année est sortie le premier jeu vidéo de l'histoire (Tennis for two) ? ",
        "possibilities" : ["1958","1965","1972","1949"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 36,
        "difficulty" : 3,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "D'après le site Metacritic, quel est le meilleur jeu de tous les temps ? ",
        "possibilities" : ["The Legend of Zelda: Ocarina of Time","Super Mario Galaxy","Tony Hawk's Pro Skater 2","Grand Theft Auto IV"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 37,
        "difficulty" : 4,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Comment devait s'appeller initialement le jeu Pacman ?",
        "possibilities" : ["Puck-man","Yellow-Ghost","Pokimane","Pokeman"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 38,
        "difficulty" : 5,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Apple a bel et bien conçu une console en 1996, comment s'appellait-elle ?",
        "possibilities" : ["La Pipp!n","La iG@me","La Apple-B0y","La Tr@mpl!n"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 39,
        "difficulty" : 3,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel est le nom de la dernière console Sega sortie en Europe ?",
        "possibilities" : ["Dreamcast","Advanced Pico Beena","Saturn","Game Gear"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 40,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "A quoi fait référence le code barre dans la nuque de l'agent 47 (Hitman) ?",
        "possibilities" : ["Sa date de naissance et son code de clone","Sa date de naissance","Son code de clone","Le numéro de téléphone de sa nounou"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 41,
        "difficulty" : 5,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Combien de consoles sont sortie depuis la première en 1972, jusqu'à 2023 ?",
        "possibilities" : ["289","155","432","528"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 42,
        "difficulty" : 5,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Combien de consoles différentes sont sortie depuis la première en 1972, jusqu'à 2023 ?",
        "possibilities" : ["289","155","432","528"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 43,
        "difficulty" : 3,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Combien de consoles différentes à sorti Sony jusqu'à 2023 ?",
        "possibilities" : ["7","8","10","12"],
        "answer" : 2,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 44,
        "difficulty" : 4,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Quelle console a été la moins vendue ?",
        "possibilities" : ["Nintendo Wii U","PlayStation Vita","Sega Dreamcast","Atari 2600"],
        "answer" : 3,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 45,
        "difficulty" : 4,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "https://i.ibb.co/rx1GdbG/Play-Station-Logo.jpg",
        "sound" : "",
        "question" : "Quel est le logo culte de Playstation ?",
        "possibilities" : ["A","B","C","D"],
        "answer" : 3,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 46,
        "difficulty" : 1,
        "category"	: "Indie",
        "type" : "CKOI" ,
        "image" : "https://i.ibb.co/bQ5gXq9/minecraft.jpg",
        "sound" : "",
        "question" : "Quel est ce jeu ?",
        "possibilities" : ["Minecraft","Roblox","CS:GO","Spelunky"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 47,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "https://i.ibb.co/j46cB9Z/switchlogo.jpg",
        "sound" : "",
        "question" : "Quel est le logo culte de la Nintendo Switch",
        "possibilities" : ["A","B","C","D"],
        "answer" : 3,
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 48,
        "difficulty" : 4,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "En quelle année est sortie la Nintendo 64 en Europe ?",
        "possibilities" : ["1997","1995","1999","2001"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 49,
        "difficulty" : 4,
        "category"	: "Triple A",
        "type" : "Histoire" ,
        "image" : "",
        "sound" : "",
        "question" : "En quelle année est sortie la Playstation 3 en Europe ?",
        "possibilities" : ["2007","2005","2008","2010"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 50,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "En quelle année est sortie le premier jeu Counter Strike ?",
        "possibilities" : ["2000","2002","1998","2004"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 51,
        "difficulty" : 3,
        "category"	: "Indie",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu de réfléxion vous permet d'incarner à la fois une Archère, un Sorcier et un Chevalier",
        "possibilities" : ["Trine","Fez","Tunic","Celeste"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 52,
        "difficulty" : 4,
        "category"	: "Indie",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Quel jeu indépendant n'existe pas ?",
        "possibilities" : ["Rouge (ou Red)","Gris (ou Grey)","Blanc (ou White)","Jaune (ou Yellow)"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 53,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "https://static.actu.fr/uploads/2019/02/KH3_E32018_Screenshot_event13_1528800812-960x640.jpg",
        "sound" : "",
        "question" : "Quel est ce jeu ?",
        "possibilities" : ["Kingdom Heart III","Toy Story 2","Final Fantasy VI","Dragon Quest IV"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 54,
        "difficulty" : 2,
        "category"	: "Casual",
        "type" : "Cultur'Jeux" ,
        "image" : "https://ddragon.leagueoflegends.com/cdn/img/champion/splash/Teemo_0.jpg",
        "sound" : "",
        "question" : "Qui est ce ?",
        "possibilities" : ["Teemo (League of Legends)","Puck (Dota)","Murky (Heroes of the Storm)","Pikachu (Pokemon)"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 55,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "https://static.fnac-static.com/multimedia/Images/FD/Comete/146275/CCP_IMG_1200x800/1927652.jpg",
        "sound" : "",
        "question" : "Qui est ce ?",
        "possibilities" : ["Marcus Fenix (Gears of War)","Solid Snake (Metal Gear)","Eddie Riggs (Brütal Legend)","Albert Wesker (Resident Evil)"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 56,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "https://www.serieously.com/app/uploads/2022/04/squelerex.jpg",
        "sound" : "",
        "question" : "Qui est ce ?",
        "possibilities" : ["Skelerex","Skeletor","Yoshi","Yoshirex"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 57,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "https://top-mmo.fr/wp-content/uploads/2022/04/Aloy-Horizon-DD-Build.jpg",
        "sound" : "",
        "question" : "Comment s'appelle ce personnage des jeux Horizon ?",
        "possibilities" : ["Aloy","Felina","Arachi","Chiquita"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 58,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "https://rare-gallery.com/thumbnail/1397115-Street-Fighter-Video-Game-Akuma.jpg",
        "sound" : "",
        "question" : "Comment s'appelle ce personnage des jeux Street Fighter ?",
        "possibilities" : ["Akuma","Zangief","Gouken","Seth"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 59,
        "difficulty" : 3,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "https://jolstatic.fr/www/captures/825/0/123350.jpg",
        "sound" : "",
        "question" : "Quelle classe des jeux Diablo est-ce ?",
        "possibilities" : ["Nécromancien","Sorcier","Moine","Chasseur de démons"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 60,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Dans le jeu Overwatch, qui porte des lunettes oranges ?",
        "possibilities" : ["Tracer","Lancer","Kiriko","Symmetra"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 61,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Dans les jeux Animal Crossing, comment s'appelle le responsable du musée ?",
        "possibilities" : ["Thibou","Tom Nook","Boba","Mandy"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 62,
        "difficulty" : 2,
        "category"	: "Indie",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Dans quel jeu peut-on jouer un agneau gourou qui construit sa secte ?",
        "possibilities" : ["Cult of the Lamb","Lamb Cult","Hades","The black Lamb"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 63,
        "difficulty" : 2,
        "category"	: "Indie",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "Dans quel jeu revit-on une boucle de 12 minutes pour éviter le meurtre de sa femme et trouver l'assassin de son père ?",
        "possibilities" : ["Twelve Minutes","Time Loop","Time Handlers","Nine Minutes"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
    {
        "id" : 64,
        "difficulty" : 2,
        "category"	: "Triple A",
        "type" : "Cultur'Jeux" ,
        "image" : "",
        "sound" : "",
        "question" : "De quelle suite de jeux, Yennefer est-elle le personnage ?",
        "possibilities" : ["The Witcher","Hogwarts Legacy","Xenobalde Chronicles","Final Fantasy"],
        "answer" : 1,
        "Hint" : "",
        "reported" : false,
        "report_message" : ""
    },
]

export default Questions;