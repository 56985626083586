import { useEffect, useState } from "react";
import Reponse from "../Components/Questions/Reponse/Reponse";
import { useLocation, useHistory } from "react-router-dom";

function Quizz(props) {
    const [timer, setTimer] = useState();
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [questions, setQuestions] = useState();
    const [score, setScore] = useState(0);
    const [answered, setAnswered] = useState([]);
    const [currentQuestionOrder, setCurrentQuestionOrder] = useState([1,2,3,4]);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        document.body.className = 'quizz'
        document.title = `Quizz : ${currentQuestion + 1}/${props.location.state.nbQuestions}`;
    }, [])

    useEffect(() => {
        setQuestions(props.location.state.questions)
        setAnswersOrder()
        setTimer(props.location.state.timeset)
    }, [])
    

    useEffect(() => {
        if(questions && currentQuestion + 1 <= questions.length && timer === 1) {
            questionsRotation()
        } else if(questions && currentQuestion + 1 > questions.length){
            history.push({
                pathname: `/resultats/${location.state.code}`,
                state: { 
                    questions: questions, 
                    code:location.state.code, 
                    score: score
                }
            });
        }    
    }, [questions, timer])

    useEffect(() => {
            if(timer === 0 && currentQuestion) {
                setTimer(props.location.state.timeset)
            } else {
                timerRotation()
            }
    }, [timer, currentQuestion])

    const getRandomInt = (max) => {
        return Math.floor(Math.random() * max);
    }

    const setAnswersOrder = () => {
        let currentOrder = currentQuestionOrder
        for (let i = 0; i < currentOrder.length; i++) {
            currentOrder[i] = getRandomInt(10)
        }
        setCurrentQuestionOrder(currentOrder)
    }

    const timerRotation = () => {
        setTimeout(() => {
            setTimer(timer - 1) 
        }, (1010));
    }

    const questionsRotation = () => {
        setTimeout(() => {
            setCurrentQuestion(currentQuestion + 1) 

            var reponses = document.querySelectorAll(".reponse");

            reponses.forEach(reponse => {
                reponse.firstChild.classList.remove('btn-outline-primary'); 
                reponse.firstChild.classList.add('btn-primary'); 
                reponse.firstChild.classList.remove('btn-danger'); 
            });

            setAnswersOrder()
        }, (timer * 1000));
    }

    const validateReponse = (id, answer) => {
        var currentScore = score;
        let answeredArray = answered
        let answeredCurrentExist = false
        let answeredCurrentId
        let answeredCurrent = {
            'id': questions[currentQuestion].id,
            'answered': true,
            'plusminus' : '+'
        }

        if(answeredArray.length > 0) {
            for (let i = 0; i < answeredArray.length; i++) {
                if(answeredArray[i].id === questions[currentQuestion].id) {
                    answeredCurrentExist = true
                    answeredCurrentId = i
                }
            }
        }

        if(!answeredCurrentExist) {
            if(id === answer) {
                setScore(currentScore + questions[currentQuestion].difficulty);
                answeredCurrentExist = true
                answeredCurrent.plusminus = "+"
            } else {
                setScore(currentScore - questions[currentQuestion].difficulty);
                answeredCurrentExist = true
                answeredCurrent.plusminus = "-"
            }

            answeredArray.push(answeredCurrent)
            setAnswered(answeredArray)
        } else {
            if(id === answer && answeredArray[answeredCurrentId].plusminus !== "+") {
                setScore(currentScore + (questions[currentQuestion].difficulty * 2));
                answered[answeredCurrentId].plusminus = "+"
            } else if(id !== answer && answeredArray[answeredCurrentId].plusminus === "+") {
                setScore(currentScore - (questions[currentQuestion].difficulty * 2))
                answered[answeredCurrentId].plusminus = "-"
            }
        }

        var reponses = document.querySelectorAll(".reponse");
        var target = document.querySelector('.reponse-'+id+' > button')

        reponses.forEach(reponse => {
            reponse.firstChild.classList.remove('btn-primary'); 
            reponse.firstChild.classList.remove('btn-danger'); 
            reponse.firstChild.classList.add('btn-outline-primary'); 
        });

        target.classList.add('btn-danger')
        target.classList.remove('btn-outline-primary')
    }

    return (

        <>
            {(questions && currentQuestion <= questions.length && questions[currentQuestion]) &&
            <>
                <div className="container">
                    <div className="row no-gutters topQuestion fullwidth">
                        <div className="infoBar d-flex align-items-end justify-content-between">
                            <span className="btn rounded-pill btn-dark btn-sm">Question {currentQuestion + 1}/{props.location.state.nbQuestions}</span>
                            {/*<p>Score : {score}</p>*/}
                            <span className="btn rounded-pill btn-info btn-sm">Difficulté : {questions[currentQuestion].difficulty}/5</span>
                            <div className="formeCheloue">
                                <span className="bg-secondary">{questions[currentQuestion].category}</span>
                                <span className="bg-warning">{questions[currentQuestion].type}</span>
                            </div>
                            <div className="timerBar fullwidth progress rounded-0">
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-danger rounded-0" role="progressbar" style={{'width':((timer*100) / props.location.state.timeset)+'%'}}></div>
                            </div>
                        </div>
                        
                        <div className="col-12">
                            {questions[currentQuestion].image && 
                            <>
                                <div className="imgContainer">
                                    <img className="bgImgContainer fullwidth" src={questions[currentQuestion].image} alt="Question bg" />
                                    <img src={questions[currentQuestion].image} alt="Question" />
                                </div>
                            </>
                            }
                        </div>
                    </div>
                    
                    <div className="row no-gutters">
                        <h2 className="questionTitle">{questions[currentQuestion].question}</h2>
                    </div>

                    <div className="row no-gutters rowReponses">
                        <Reponse id="1" order={currentQuestionOrder[0]} clic={() => validateReponse(1, questions[currentQuestion].answer)}>{questions[currentQuestion].possibilities[0]}</Reponse>  
                        <Reponse id="2" order={currentQuestionOrder[1]} clic={() => validateReponse(2, questions[currentQuestion].answer)}>{questions[currentQuestion].possibilities[1]}</Reponse>  
                        <Reponse id="3" order={currentQuestionOrder[2]} clic={() => validateReponse(3, questions[currentQuestion].answer)}>{questions[currentQuestion].possibilities[2]}</Reponse>  
                        <Reponse id="4" order={currentQuestionOrder[3]} clic={() => validateReponse(4, questions[currentQuestion].answer)}>{questions[currentQuestion].possibilities[3]}</Reponse>                       
                    </div>
                </div>
            </>
            }
        </>

    );
}


export default Quizz;