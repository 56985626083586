import { useState, useEffect } from 'react';
import Bouton from '../Components/Bouton/Bouton';
import { useHistory } from 'react-router-dom';


function CreateGame(props) {
    const history = useHistory();
    const [GamePass, setGamePass] = useState()
    const [CreatorName, setCreatorName] = useState(false)
    const [nbQuestions, setNbQuestions] = useState(10)
    const [timeset, setTimeset] = useState(20)

    useEffect(() => {
        document.body.className = 'creategame'
        document.title = `ZGQ : Création d'une partie`;
    }, [])

    useEffect(() => {
        setGamePass(generatePassword().toUpperCase())
    }, [])

    const sendsocket = (obj) => {   
        props.socket.emit('joinRoom', obj);
    }

    const generatePassword = () => {
        var nbChars = 6;
        var charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        var password = "";
        for (var i = 0; i < nbChars; i++) {
            password += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return password;
    }

    const createGame = (event) => {
        event.preventDefault();
        if(CreatorName && CreatorName.length > 4) {
            sendsocket({
                name : CreatorName,
                room : GamePass,
                creator: true
            })
            history.push({
                pathname: `/lobby/${GamePass}`,
                state: { creator: CreatorName, code:GamePass, nbQuestions:nbQuestions, timeset:timeset  }
            });
        } else {
            alert('Aucun nom inscrit ou nom trop court')
        }
    }

    return (

        <>
            <div className='container'>
                <div className='row no-gutters curvy-container'>
                    <div className='col-8 col-md-6 my-4 mx-auto'>
                        <h3>Your game password</h3>
                        <h1 className='text-primary text-uppercase'>{GamePass && GamePass}</h1>

                        <form>
                            <div className="form-group">
                                <label htmlFor="creatorName">Votre nom</label>
                                <input onChange={(event) => setCreatorName(event.target.value)} type="text" className="form-control" id="creatorName" placeholder="Votre nom"/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="nbQuestions">Nombre de questions</label>
                                <input min="10" max="50" onChange={(event) => setNbQuestions(event.target.value)} type="number" value={nbQuestions} className="form-control" id="nbQuestions" placeholder="Nombre de questions"/>
                            </div>
                            <Bouton className="btn-primary mt-2" clic={(event) => createGame(event)}>Créer</Bouton>
                        </form>
                    </div>
                </div>
            </div>
        </>

    );
}

export default CreateGame;