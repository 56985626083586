import { useEffect } from "react";
import { NavLink } from "react-router-dom";


function Homepage(props) {
    
    useEffect(() => {
        document.body.className = 'homepage'
        document.title = `ZGQ : Homepage`;
    }, [])
    
    
    return (

        <>
            <div className='container homepage'>
                <div className='curvy-container row no-gutters'>
                    <div className='col-8 col-md-6 my-4 mx-auto'>
                    Bienvenue sur ZeGameQuizzzz ! <br />
                        <NavLink className={'btn btn-primary d-block mb-2'} to={'/create-game'}>
                            Créer une partie
                        </NavLink>
                        <NavLink className={'btn btn-secondary d-block'} to={'/join-game'}>
                            Rejoindre une partie avec un mot de passe
                        </NavLink>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Homepage;