import { useState } from 'react';

function Participant(props) {
    return (

        <>
            <div className={`col-6 col-md-3 p-2`}>
                <p className={`m-0 ${props.isCreator ? 'bg-warning' : 'bg-primary'} w-100 h-100 rounded-2 d-flex align-items-center justify-content-center p-2 text-white`}>{props.name}</p>
            </div>
        </>

    );
}

export default Participant;