import { useLocation, useHistory, useParams } from "react-router-dom";
import Participant from "../Components/Participants/Participant";
import Bouton from "../Components/Bouton/Bouton";
import Questions from "../Components/Questions/Questions";
import { useEffect, useState } from "react";


function Lobby(props) {
    const [QuizzQuestions, setQuizzQuestions] = useState()
    const { gamecode } = useParams();
    const [users, setUsers] = useState([]);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        document.body.className = 'lobby'
        document.title = `ZGQ : En attente de joueurs...`;
    }, [])

    useEffect(() => {
        if(location && location.state && location.state.creator && location.state.code && location.state.timeset && location.state.nbQuestions){
            defineQuestions()
        }    
    }, [])

    useEffect(() => {
        props.socket.on('userJoined', (data) => {
            console.log(users)
            setUsers((prevUsers) => [...prevUsers, [data.name, data.creator]]);
        });

        props.socket.on('roomIsClosed', () => {
            console.log('pouet room fermée')
            history.push({
                pathname: `/join-game`
            });
        });

        props.socket.on('quizzStarted', (room,questions,timeset,nbQuestions) => {
            history.push({
                pathname: `/quizz/${room}`,
                state: { 
                    questions: questions, 
                    code:room, 
                    timeset:timeset, 
                    nbQuestions: nbQuestions,
                    creator: false
                }
            });
        });
        
        // return () => {
        //   props.socket.disconnect();
        // };
      }, [props.socket]);



    const defineQuestions = () => {
        let newQuestions = Questions
        let currentIndex = newQuestions.length,  randomIndex;
      
        while (currentIndex !== 0) {
      
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
      
            [newQuestions[currentIndex], newQuestions[randomIndex]] = [
                newQuestions[randomIndex], newQuestions[currentIndex]
            ];
        }

        setQuizzQuestions(newQuestions.slice(0,location.state.nbQuestions))
    }

    const closeRoomHandler = () => {
        props.socket.emit('closeRoom', gamecode);

        if(location && location.state && location.state.creator) {
            history.push({
                pathname: `/create-game`
            });
        } else {
            history.push({
                pathname: `/join-game`
            });
        }
    }

    const startQuizzHandler = () => {
        props.socket.emit('startQuizz', gamecode,QuizzQuestions,location.state.timeset,location.state.nbQuestions)
        history.push({
            pathname: `/quizz/${gamecode}`,
            state: { 
                questions: QuizzQuestions, 
                code:gamecode, 
                timeset:location.state.timeset, 
                nbQuestions: location.state.nbQuestions,
                creator: location.state.creator
            }
        });
    }

    return (

        <>
            <div className='container'>
                <div id="allParticipants" className='row no-gutters curvy-container'>
                    <div className='col-8 col-md-6 my-4 mx-auto'>
                        <h3>Liste des participants de la room {gamecode}</h3>
                        
                        {users.map((user, index) => (
                            <>
                                <Participant key={index} isCreator={user[1] ? true : false} name={user} />
                            </>
                        ))}
                    </div>
                </div>
                {(location && location.state && location.state.creator) &&
                    <>
                        <Bouton className="btn-primary mt-2" clic={() => startQuizzHandler()}>Start quizzzzz</Bouton>
                        <Bouton className="btn-danger mt-2" clic={() => closeRoomHandler()}>FERMER LA SALLE</Bouton>
                    </>
                }
            </div>
        </>

    );
}

export default Lobby;