import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Homepage from './Pages/Homepage';
import CreateGame from './Pages/CreateGame';
import JoinGame from './Pages/JoinGame';
import Lobby from './Pages/Lobby';
import Quizz from './Pages/Quizz';
import Results from './Pages/Results';
import io from 'socket.io-client';
import Header from './Components/Header/Header';
const socket = io('https://servquizz.patatedouce-studio.fr/');

function App() {
  return (
   
    <BrowserRouter>
          <Header />
          <Switch>
              <Route path="/" exact render={() => <Homepage />} />
              <Route path="/create-game" exact render={() => <CreateGame socket={socket} />} />
              <Route path="/lobby/:gamecode" exact render={(props) => <Lobby socket={socket} {...props}/>} />
              <Route path="/join-game" exact render={() => <JoinGame socket={socket} />} />
              <Route path="/quizz/:gamecode" exact render={(props) => <Quizz socket={socket} {...props}/>} />
              <Route path="/resultats/:gamecode" exact render={(props) => <Results socket={socket} {...props}/>} />
              <Route render={() => <h1>La page 404 là</h1>}  />
          </Switch>
      </BrowserRouter>
  );
}

export default App;
