import { NavLink } from "react-router-dom";

function Header() {
    return (

        <>
            <header className="container-fluid">
                <NavLink to={'/'}>Home</NavLink>
            </header>
        </>

    );
}

export default Header;